  /* ============================================
  PAGE SNAPPING
============================================= */

.page-container {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    padding: 20px 0;
    background: #f8f9fa;
    min-height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 80px;
  }
  
.page-wrapper {
  height: 100vh;
  height: 100dvh;
  overflow-y: scroll;
  scroll-snap-type: none;
  position: relative;
  /* border: 90px solid red; */
}

.scroll-section {
/*  scroll-snap-stop: always;     maybe usefull on mobile */
  min-height: 100dvh;
  scroll-snap-align: start;
  display: flex;
  justify-content: center;
  padding: 66px 30px 30px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
 /* &:nth-child(even) {
    border: solid 1px var(--Line);
  } */
}

  
  .hcontent-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1200px;
    width: 100%;
    position: relative;
  }

  .mcontent-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    border-radius: 80px;
  }
    /*
  .page-wrapper {
    display: flex;
    flex-direction: column;
    gap: 400px;
    max-width: 1200px;
    margin: 0 auto 200px;
    width: 100%;
    padding: 0 20px;
    position: relative;
  }
  */
  

  .landing-section {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .left-section {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 100%;
    position: relative;
    z-index: 20;
    background-color: transparent;
  }

  .logogridbtn {
    display: flex;
    gap: 40px;
    align-items: center;
    margin-top: 20px;
  }

  .logo-grid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;
    width: 100%; 
    mix-blend-mode: luminosity;
    gap: 20px;
  }

  .logo-grid img {
    filter: grayscale(100%) contrast(1.1);
  }

  /* Base card mini */

  .base-card-mini {
    padding: 20px;
    border-radius: 40px;
    background: #F0F0F0;
    box-shadow: 0px 5px 45px 15px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
  }
  
  .base-card-mini:hover {
    transform: translateY(-2px) !important;
    box-shadow: 0px 8px 30px 15px rgba(0, 0, 0, 0.15);
  }
  
  .base-card-mini h3 {
    font-size: 20px;
    margin: 0;
    padding: 0;
    line-height: 100%;
    font-weight: 700;
    margin-bottom: 8px;
  }
  
  .base-card-mini p {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 300;
    line-height: 21.6px;
    text-align: left;
    color: var(--Light-Text);
    flex: 1;
    display: flex;
    align-items: center;
  }

  /* Base card long */

  .base-card-long {
    border-radius: 40px;
    background: #F0F0F0;
    box-shadow: 0px 5px 45px 15px rgba(0, 0, 0, 0.15);
    width: 100%;
    max-width: 600px;
    min-height: 80px;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid transparent;
    transition: border 0.3s ease;
  }

  .base-card-long h3 {
    margin: 0;
    padding: 0;
    line-height: 100%;
    margin-bottom: 8px;
  }

  .base-card-long p {
    margin: 0;
    padding: 0;
    line-height: 21.6px;
    text-align: left;
    flex: 1;
    display: flex;
    align-items: center;
  }
  
  .base-card-long:hover {
    border: 1px solid var(--Solid-CTA);
  }
  

  /* ==========================
  DISABLE SCROLL SNAP ON MOBIlE and other styling
 ========================== */

 @media (max-width: 700px) {
      .page-wrapper {
      height: 100vh;
      height: 100dvh;
      overflow-y: scroll;
      scroll-snap-type: none;
      position: relative;
      /* border: 90px solid red; */
    }

      .scroll-section {
      /*  scroll-snap-stop: always;     maybe usefull on mobile */
        min-height: 100dvh;
        scroll-snap-align: none;
        display: flex;
        justify-content: center;
        padding: 66px 30px 30px;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
      /* &:nth-child(even) {
          border: solid 1px var(--Line);
        } */
      }

      .left-section {
        justify-content: center !important;
        align-items: center !important;
      }

      .left-section h1,
      .left-section h2,
      .left-section p {
        text-align: center;
      }

      .logo-grid {
        justify-content: center;
      }

      .logogridbtn {
        flex-direction: column;
      }


 }