.rainbow {
  background: var(--main-gradient);
 -webkit-background-clip: text;
 background-clip: text;
 -webkit-text-fill-color: transparent;
 transition: background 0.4s ease, -webkit-text-fill-color 0.4s ease;
 }

 .tag-link {
 text-decoration: none;
 color: var(--Light-Text);
 font-weight: 700;
 transition: color 0.3s ease;
 }
 
 .tag-link:hover {
 color: black;
 }

 .poll-card {
  width: 268px;
  padding: 20px;
  border-radius: 40px;
  border: 1px solid transparent;
  overflow: hidden;
  transform: translateZ(0);
  background: var(--Main-BG, #F0F0F0);
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  box-shadow: 0px 5px 45px 15px rgba(0, 0, 0, 0.15);
}

.poll-card-hover {
  border: 1px solid var(--Solid-CTA)
}

.poll-card-image {
  width: 228px;
  height: 90px;
  border-radius: 20px;
  object-fit: cover;
  display: block;
  margin-bottom: 15px;
}

.poll-card-title {
  height: 50px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  transition:background 0.4s ease, -webkit-text-fill-color 0.4s ease;
}

.poll-card-description {
  height: 45px;
  font-size: 16px;
  margin-bottom: 0;
  line-height: 1.4;
  color: #6c757d;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.tag {
  display: inline-block;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 0.3rem 0.8rem;
  font-size: 0.8rem;
  font-weight: 500;
  color: #333;
}



.cta-link {
  color: black;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.cta-link:hover {
  text-decoration: underline;
  color: #333;
}

.cta-link .arrow {
  transition: transform 0.3s ease;
}

.cta-link:hover .arrow {
  transform: translateX(5px);
}

.cta-button {
  background: none;
  border: none;
  color: black;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 0;
}

.cta-button:hover {
  color: #514949;
  text-decoration: underline;
}

.cta-button .arrow {
  transition: transform 0.3s ease;
}

.cta-button:hover .arrow {
  transform: translateX(5px);
}

.gradient-button {
  background: linear-gradient(to right, #d14366, #f58c6c);
  color: #fff;
  font-family: 'Arial', sans-serif;
  font-weight: 600;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  border-radius: 50px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.gradient-button:hover {
  background: linear-gradient(to right, #b63659, #e77a5b);
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);
}