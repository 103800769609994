/* Card styling */
.signin-card {
    width: 36rem;
    border: none;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    border-radius: 8px;
    background-color: #f8f8f8;
}

/* Form container */
.Auth-form-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

/* Input fields with flat underline */
.signin-input {
    width: 100%;
    border: none;
    border-bottom: 2px solid #ccc;
    padding: 0.8rem;
    font-size: 1rem;
    background-color: transparent;
    outline: none;
    border-radius: 0; /* Ensures no rounded edges */
}

/* Flat underline on focus */
.signin-input:focus {
    border-bottom: 2px solid #000;
}

/* Button styling */
.signin-btn {
    width: 100%;
    padding: 0.8rem 0;
    font-size: 1rem;
    background: var(--main-gradient);
    color: white;
    border: none;
    cursor: pointer;
    transition: all 0.3s;
}

.signin-btn:hover {
    background: black;
    color: var(--Main-BG);
}

/* Success message */
.success-message {
    color: red; /* Error message styling */
    font-weight: bold;
    margin-top: 1rem;
    text-align: center;
}

/* Link to Sign Up */
.signup-link {
    margin-top: 1rem;
    text-align: center;
    font-size: 0.9rem;
}

.signup-link a {
    color: #007bff;
    text-decoration: none;
}

.signup-link a:hover {
    text-decoration: underline;
}

/* Google Login button styling */
.google-login {
    margin-top: 1.5rem;
    text-align: center;
    border-radius: 20px;
}

.google-login > div {
    display: inline-block; /* Centers Google Login button */
}

/* 
new google styling 
*/

.custom-google-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 58px; /* Your specified height */
    width: 400px; /* Match your current button width */
    background-color: var(--Main-BG);
    color: var(--pcolor);
    border: 1px solid #dadce0;
    border-radius: 29px; /* Rounded edges (pill shape) */
    font-family: 'inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 0 auto; /* Center the button */
    padding: 0 16px;
  }
  
  .custom-google-btn:hover {
    background-color: black;
    color: var(--Main-BG);
  }
  
  .google-logo {
    height: 24px;
    width: 24px;
    margin-right: 12px;
  }
  
  .custom-google-btn span {
    margin-left: 8px;
    font-weight: 500;
  }