:root {
    font-family: 'Inter', sans-serif;
    --Solid-CTA: #9C52F2;
    --Main-BG: #f8f9fa;
    --LG-BG: #E8E8E8;
    --Line: #878787;
    --Light-Text: #8F8F8F;
    --Heading:  rgba(0, 0, 0, 0.80);
    --pcolor: #212529;
    --psize: 16px;
    --main-gradient: linear-gradient(90deg, #BD8EF3 0%, #7F24FF 100%);
  }

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

/* Then apply it to your app */


body {
    background: #f8f9fa;
    font-family: 'Inter', sans-serif;
}

.ellipsis-2-lines {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* number of lines to show */
    overflow: hidden;
}

.ellipsis-3-lines {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* number of lines to show */
    overflow: hidden;
}

.black {
    font-family: 'Inter', sans-serif; /* Clean sans-serif font */
    font-weight: 400; /* Bold */
    color: var(--Heading); /* Black */
    margin: 0;
    padding: 0;
}

hr {
    border: 0;
    height: 1px;
    background: var(--Line, #878787);
    margin: 20px 0 80px 0;
}

/* ============================================
Text Styling
============================================= */

h1 {
    font-size: 52px;
    font-weight: 700;
    line-height: 1.4em;
    color: var(--Heading);
}

h1.title{
  font-size: 96px;
  font-weight: 700;
  line-height: 1.4em;
  color: var(--Heading);
}

h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.4em;
    color: var(--Heading);
}

h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.4em;
    color: var(--Heading);
    margin-bottom: 0; /* Removes BOOTSTRAP styling */
}

h4 {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.4em;
    color: var(--Heading);
}

p {
    font-size: var(--psize, 16px);
}

.textcontainer p {
    line-height: 1.5em;
    color: var(--pcolor);
    margin-bottom: 10px;
  }
  
.textcontainer h2 {
    margin-bottom: 14px;
}

.textcontainer h4 {
    margin-bottom: 8px;
    margin-top: 18px;
}

/* ============================================
Image and Text Container Styling
============================================= */

 /* For justifying content fully to left or right on main page sections */
.imgtxtcontainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 80px;
}

/* Centered Container for main page sections */
.cntimgtxtcontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 80px;
}

/* display sub compnents in columns */
.flexcolumn {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 80px;
}

.contentcontainer {
    display: flex;
    width: 100%;
    max-width: 600px;
    flex-direction: column;
    gap: 30px;
}

.imgcontainer {
    display: flex;
    max-height: 600px;
    max-width: 600px;
    aspect-ratio: 1/1;
}

.imgcontainer img {
    height: 100%;
    width: 100%;
    border-radius: 100%;
    object-fit: cover;
}

.h2-size {
    font-size: 52px;
    font-weight: 700;
}

.grey {
    font-family: 'Union'; /* Contemporary sans-serif font */
    font-weight: 600; /* Semi-bold */
    font-size: 36px; /* Adjust based on your layout */
    color: grey; 
    margin: 0;
    padding: 0;
}

.dark-button {
    background-color: #000; /* Black background */
    color: #fff; /* White text */
    font-family: 'Union', sans-serif; /* Use a clean sans-serif font */
    font-weight: 600; /* Semi-bold */
    font-size: 16px; /* Adjust font size */
    padding: 12px 24px; /* Padding for a balanced look */
    border: none; /* Remove border */
    border-radius: 24px; /* Rounded edges */
    text-align: center; /* Center the text */
    cursor: pointer; /* Pointer cursor on hover */
    display: inline-block; /* Ensure button-like behavior */
    transition: all 0.3s ease; /* Smooth hover effect */
}

.dark-button:hover {
    background-color: #333; /* Slightly lighter black on hover */
}

.light-button {
    background-color: #f8f9fa; /* Light gray background */
    color: rgb(61, 58, 58); /* Black text */
    font-family: 'Inter', sans-serif; /* Use a clean sans-serif font */
    font-weight: 400; /* Semi-bold */
    font-size: 16px; /* Adjust font size */
    padding: 12px 24px; /* Padding for a balanced look */
    border: 1px solid #ccc; /* Subtle border */
    border-radius: 32px !important; /* Rounded edges */
    text-align: center; /* Center the text */
    cursor: pointer; /* Pointer cursor on hover */
    display: inline-block; /* Ensure button-like behavior */
    transition: all 0.3s ease; /* Smooth hover effect */
}

.light-button:hover {
    background-color: #e6e6e6; /* Slightly darker gray on hover */
}

.link {
    text-decoration: none; /* Removes underline */
    color: inherit; /* Inherits the color from the parent element */
    font-weight: 400;
}

.popup-div {
    display: flex;
    height: 52px;
    padding: 1px 1px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.lr-div {
    display: flex;
    align-items: center;
    gap: 40px;
    align-self: stretch;
}

.fabulous-button {
    border-radius: 32px !important;
    background: var(--Horizontal-Gradient, linear-gradient(90deg, rgba(151, 73, 242, 0.30) 0%, #7F24FF 100%));  
    color: var(--MAIN-BG, #F0F0F0);

    /* Body */
    font-family: Inter;
    font-size: 18px; /* Increase font size for larger text */
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* Adjust line height proportionally */

    
    min-width: 150px; /* Ensure a minimum width */
    min-height: 50px; /* Ensure a minimum height */

    /* Center text */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    outline: none; /* Removes the focus outline */
    box-shadow: none; /* Removes any potential default shadow */
    border: transparent;
}

/* added this class for nav bar, had to restyle*/
.nav-button {
    font-size: var(--psize, 16px);
}

.nav-button-selected {
    border-radius: 32px !important;
    display: flex;
    height: 40px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    color: var(--Main-BG, #F0F0F0);
    background: var(--Solid-CTA, #9C52F2);
    border: 1px solid transparent;
}

.nav-button-default {
    border-radius: 32px !important;
    display: flex;
    height: 40px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    background: var(--LG-BG, #9C52F2);
    border: 1px solid transparent;
}

.nav-button-default:hover {
    border: 1px solid var(--Solid-CTA, #9C52F2);
}   

@media (max-width: 576px) {
    .nav-button,
    .nav-button-selected,
    .nav-button-default {
        font-size: 10px;
    }
}


.white-button {
    border-radius: 32px !important;
    border: 1px solid var(--Outline, #878787);
     /* Body */
     font-family: Union;
     font-size: 18px; /* Increase font size for larger text */
     font-style: normal;
     font-weight: 400;
     line-height: 120%; /* Adjust line height proportionally */
 
     min-width: 150px; /* Ensure a minimum width */
     min-height: 50px; /* Ensure a minimum height */
 
     /* Center text */
     display: flex;
     align-items: center;
     justify-content: center;
     text-align: center;
}

.popup-card {
    display: flex;
    width: 800px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    border-radius: 50%;
    background: transparent;
    box-shadow: transparent;
}

.loading-screen {
    position: fixed; /* Ensures the spinner stays in one place */
    top: 0;
    left: 0;
    width: 100vw; /* Full screen width */
    height: 100vh; /* Full screen height */
    display: flex;
    align-items: center; /* Vertically center */
    justify-content: center; /* Horizontally center */
}


.active-tag .tag-link {
    font-weight: bold; /* Bold only for the active tag */
    color: black; /* Ensure the active tag's color remains consistent */
}

.topbar-div {
    display: flex;
    width: 1200px;
    max-width: 1440px;
    padding: 20px 0px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border-radius: 40px;
}

.settings-button {
    background-color: #f8f9fa; /* Light grey background */
    border: 1px solid #ced4da;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
}

.settings-button:hover {
    background-color: #e9ecef; /* Slightly darker grey on hover */
}

.testflexbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    gap: 20px;
    margin-bottom: 160px;

}

/* New PollList styles */
.poll-list-container-nbs {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 20px;
    border-radius: 10px;
}

.tags-column {
    position: relative;
}

.sticky-header {
    position: sticky;
    margin-top: 100px;
    top: 80px;
}

.tags-list {
    list-style-type: none;
    padding: 0;
}

.tag-item {
    margin-bottom: 10px;
}


/* New explore.jsx top styles */

.home-container-nbs{
    display: flex;
    justify-content: center;
    margin-bottom: 600px;
}

.content-wrapper-nbs{
    display: flex;
    max-width: 1200px;
    flex-direction: column;
    gap: 20px;
    margin: 80px;
}

.hero-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-image img {
    width: 100%;
    border-radius: 40px;
}

.hero-text-nbs {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    gap: none;
}

.subjects-section {
    margin-top: 80px;
}

/* ============================================
BLOB ANIMATION
============================================= */

.blobcontainer {
    width: 600px;
    height: 600px;
    position: relative;
    border-radius: 300px;
    box-shadow: inset 0 0 80px rgba(50, 50, 50, 0.03);
  }

  .blobcontainer:after {
    content: "";
    width: 100%;
    height: 100%;
    background: url("https://i.imgur.com/PsjPzdO.png");
    background-size: 200px;
    mix-blend-mode: overlay;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 300px;
  }

  .blobcontainer .blobs {
    width: 300px;
    height: 300px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .blobcontainer .blobs .blob {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    position: absolute;
    background-color: #9C52F2;
    mix-blend-mode: overlay;
    filter: blur(40px);
  }
  .blobcontainer .blobs .blob.a {
    background-color: #b075f5;
    -webkit-animation: circular2 40s linear infinite;
            animation: circular2 40s linear infinite;
  }
  .blobcontainer .blobs .blob.b {
    background-color: #c27de7;
    -webkit-animation: circular 20s linear infinite;
            animation: circular 20s linear infinite;
  }
  .blobcontainer .blobs .blob.c {
    -webkit-animation: circular 60s linear infinite;
            animation: circular 60s linear infinite;
  }
  
  @-webkit-keyframes circular {
    0% {
      transform: translate(0, -120px);
    }
    25% {
      transform: translate(120px, 0);
    }
    50% {
      transform: translate(0, 120px);
    }
    75% {
      transform: translate(-120px, 0);
    }
    100% {
      transform: translate(0, -120px);
    }
  }
  
  @keyframes circular {
    0% {
      transform: translate(0, -120px);
    }
    25% {
      transform: translate(120px, 0);
    }
    50% {
      transform: translate(0, 120px);
    }
    75% {
      transform: translate(-120px, 0);
    }
    100% {
      transform: translate(0, -120px);
    }
  }
  @-webkit-keyframes circular2 {
    0% {
      transform: translate(0, 120px);
    }
    25% {
      transform: translate(-120px, 0);
    }
    50% {
      transform: translate(0, -120px);
    }
    75% {
      transform: translate(120px, 0);
    }
    100% {
      transform: translate(0, 120px);
    }
  }
  @keyframes circular2 {
    0% {
      transform: translate(0, 120px);
    }
    25% {
      transform: translate(-120px, 0);
    }
    50% {
      transform: translate(0, -120px);
    }
    75% {
      transform: translate(120px, 0);
    }
    100% {
      transform: translate(0, 120px);
    }
  }



/* ============================================
RESPONSIVE DESIGN
============================================= */

/* For mobile devices */

@media (max-width: 1200px) {

  h1 {
    font-size: 30px;
    font-weight: 700;
    line-height: 1.4em;
    color: var(--Heading);
  }

  h2 {
      font-size: 24px;
      font-weight: 700;
      line-height: 1.4em;
      color: var(--Heading);
  }

  h3 {
      font-size: 18px;
      font-weight: 700;
      line-height: 1.4em;
      color: var(--Heading);
      margin-bottom: 0; /* Removes BOOTSTRAP styling */
  }

  h4 {
      font-size: 18px;
      font-weight: 700;
      line-height: 1.4em;
      color: var(--Heading);
  }

  p {
      font-size: 16px;
  }


  .imgtxtcontainer {
    gap: 40px;
  }


  .flexcolumn {
    gap: 40px;
}

}

@media (max-width: 992px) {
  .imgtxtcontainer.r {
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
  }

  .imgtxtcontainer.r .contentcontainer {
    align-items: center;
  }

}


@media (max-width: 700px) {
  .imgtxtcontainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
  }



  .imgcontainer {
    display: flex;
    max-height: 300px;
    max-width: 300px;
    aspect-ratio: 1/1;
}

  .contentcontainer {
    align-items: center;
  }

  h1.title{
    font-size: 52px;
  }

  .flexcolumn {
    justify-content: space-between;
    width: 100%;
    gap: 20px;
  }


}

@media (max-width: 475px) {
  .flexcolumn .textcontainer {
    h4 {
      font-size: 14px; /* Adjust this value to your preferred size */
    }
    
    p {
      font-size: 14px; /* Adjust this value to your preferred size */
    }
  }
}