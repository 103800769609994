/* Card styling */
.signup-card {
    width: 36rem;
    border: none;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    border-radius: 8px;
    background-color: white;
}

/* Form container */
.Auth-form-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

/* Input fields */
.signup-input {
    width: 100%;
    border: none;
    border-bottom: 2px solid #ccc;
    padding: 0.8rem;
    font-size: 1rem;
    background-color: transparent;
    outline: none;
    border-radius: 0; /* Ensures no rounded edges */
}

/* Flat underline when focused */
.signup-input:focus {
    border-bottom: 2px solid var(--Solid-CTA); /* anges underline to black on focus */
}


/* Success message */
.success-message {
    color: green;
    font-weight: bold;
    margin-top: 1rem;
    text-align: center;
}

/* Checkbox styling */
form .form-group input[type="checkbox"] {
    margin-right: 0.5rem;
}

form .form-group label {
    font-size: 0.9rem;
}

/* Log In link */
.signin-link {
    margin-top: 1rem;
    text-align: center;
    font-size: 0.9rem;
}

.signin-link a {
    color: #007bff;
    text-decoration: none;
}

.signin-link a:hover {
    text-decoration: underline;
}

/* Add a global font style */
body {
    font-family: "Inter", "Roboto", "Segoe UI", Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Ensure inputs and buttons inherit the font */
.signup-card,
.Auth-form-container,
.signup-form input,
.signin-link {
    font-family: inherit; /* Ensures consistent font styling */
}

.signup-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
}

.container-div {
    display: flex;
    padding: 80px 80px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}