/* Base Layout & Container Styles */
.about-container {
    max-width: 1920px;
    margin: 0 auto;
    padding: 220px 0;
    background: #f8f9fa;
    display: flex;
    flex-direction: column;
    gap: 80px;
  }
  
  .about-content-wrapper {
    display: flex;
    flex-direction: column;
    gap : 400px;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    padding: 0 20px;
  }
  
  /* Hero section styles */
  .who-we-are-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .who-we-are-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 600px;
    height: 380px;
    position: relative;
    z-index: 10;
  }
/* Contribution */

.section-base {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px;
  width: 100%;
}

.section-base-vc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 80px;
  width: 100%;
}


.section-title {
  margin-bottom: 18px;
}

/* Specific section styles */
.maincontainer {
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 600px;
}

.privacy-features {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  margin-top: 24px;
}

.privacy-feature {
  display: flex;
  align-items: center;
  gap: 8px;
}

.feature-text {
  font-size: 18px;
  color: #7F24FF;
}

/* How it works section */
.steps-section {
  padding: 80px 0px;
}

.multistep {
  display: flex;
  flex-direction: column;
  gap: 80px;
  align-items: center;
}



.steps-container {
  display: flex;
  gap: 120px;
}

.step-card {
  flex: 1;
  border-radius: 50px 0 0 0;
  background: #f8f9fa;
  position: relative;
}

/* Divider line between cards */
.step-card:not(:last-child)::after {
  content: '';
  position: absolute;
  right: -60px;
  top: 50%;
  transform: translateY(-50%);
  height: 115%;
  width: 1px;
  background: #878787;
}

.step-header {
  display: flex;
  height: 70px;
  align-items: center;
  gap: 10px;
  margin-bottom: 24px;
}

.step-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: 1px solid transparent;
  background:
      linear-gradient(#f8f9fa, #f8f9fa) padding-box,
      linear-gradient(90deg, rgba(151, 73, 242, 0.3) 0%, #7F24FF 100%) border-box;
  font-size: 24px;
  line-height: 33.6px;
  font-weight: 700;
  color: #9C52F2;
  margin-right: 10px;
}

/* 
====================
MOBILE RESPONSIVENESS
====================
*/

@media (max-width: 1000px) {
  .steps-container {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    align-items: center;
    gap: 60px;
  }
  .step-card:not(:last-child)::after {
    display: none;
  }
}

@media (max-width: 768px) {
  .section-base {
    flex-direction: column;
    gap: 40px;
  }

  .steps-container {
    gap: 40px;
  }

  .privacy-features {
    justify-content: center;
  }
}
